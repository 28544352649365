import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import InsightsIcon from '@mui/icons-material/Insights'; // Import the new icon
import TableChartIcon from '@mui/icons-material/TableChart';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getLocalStorage } from '../../helper/LocalStorage';
import ReleaseNotes from './components/ReleaseNotes';
import WarningMessage from './components/WarningMessage';

function Dashboard() {
  const matches = useMediaQuery('(min-width:600px)');
  const user = useSelector((state) => state.user.userProfile);
  const selectedSite = useSelector((state) => state.user.loginResponse.selectedSite);

  const hideReleaseNotesKey = 'hide-release-notes-20241009';
  const hideReleaseNotes = getLocalStorage(hideReleaseNotesKey);
  const noLongerShowReleaseNotes = Date.now() > new Date('2024-11-09T00:00:00');

  const [hideReleaseNotification, setHideReleaseNotification] = React.useState(false);
  React.useEffect(() => {
    setHideReleaseNotification(!hideReleaseNotes && !noLongerShowReleaseNotes);
  }, [hideReleaseNotes, noLongerShowReleaseNotes]);

  const isUserAdminOrAppManager = user?.user_permission === 'Admin'
    || user?.user_permission === 'GroupAdmin'
    || user?.user_permission === 'User'
    || user?.user_permission === 'Viewer';

  const warningMsgEndDate = new Date('2024-08-19T15:00');
  const maintenanceDate = warningMsgEndDate.toDateString();
  const warningMsg = 'Prism will be down during the scheduled time';

  return (
    <Container maxWidth="xl" className="relative h-full pt-6">
      <Typography variant={matches ? 'h4' : 'h5'} className="pb-8 font-gothamlight" textTransform="capitalize">
        Welcome
        {' '}
        {user.sub}
      </Typography>
      {(hideReleaseNotification && isUserAdminOrAppManager && new Date() > new Date(warningMsgEndDate))
        && <ReleaseNotes hideKey={hideReleaseNotesKey} onHide={() => setHideReleaseNotification(false)} />}
      {(new Date() < new Date(warningMsgEndDate)) && <WarningMessage date={maintenanceDate} start="10:00" end="15:00" message={warningMsg} />}
      <div>
        <div className="w-full mb-4 border-b border-neutral-600">
          <Typography variant="h6" className="tracking-widest font-gothambold">
            QUICK LINKS
          </Typography>
        </div>
        <Stack
          direction="row"
          spacing={matches ? 8 : 2}
          justifyContent={matches ? 'flex-start' : 'space-evenly'}
        >
          {user?.user_groupName !== 'ImageChecker' && selectedSite?.SiteCode !== 'OC' && selectedSite?.SiteCode !== 'JQ' && (
            <a
              href="/dropship/wizards/product"
              className={`
              bg-white
              shadow
              w-[150px] h-[150px] md:w-64 md:h-64
              active:scale-95 transition-transform
              hover:border-neutral-200 border border-transparent
            `}
            >
              <div className="flex flex-col items-center justify-center h-full gap-2 md:gap-8">
                <AutoFixHighIcon className="text-[4rem] md:text-[6rem]" />
                <Typography
                  variant={matches ? 'h5' : 'h6'}
                  textAlign="center"
                  letterSpacing="1px"
                  fontFamily="gothambold"
                >
                  DROPSHIP
                  <br />
                  WIZARD
                </Typography>
              </div>
            </a>
          )}
          {user?.user_groupName !== 'ImageChecker' && selectedSite?.SiteCode !== 'OC' && selectedSite?.SiteCode !== 'JQ' && isUserAdminOrAppManager && (
            <Link
              to="/product-info/products"
              className={`
                bg-white
                shadow
                w-[150px] h-[150px] md:w-64 md:h-64
                active:scale-95 transition-transform
                hover:border-neutral-200 border border-transparent
              `}
            >
              <div className="flex flex-col items-center justify-center h-full gap-2 md:gap-8">
                <TableChartIcon className="text-[4rem] md:text-[6rem]" />
                <Typography
                  variant={matches ? 'h5' : 'h6'}
                  textAlign="center"
                  letterSpacing="2px"
                  fontFamily="gothambold"
                >
                  PRODUCTS
                  <br />
                  TABLE
                </Typography>
              </div>
            </Link>
          )}
          {user?.user_groupName !== 'ImageChecker' && selectedSite?.SiteCode !== 'JQ' && (
            <a
              href="/bulk-operations"
              className={`
              bg-white
              shadow
              w-[150px] h-[150px] md:w-64 md:h-64
              active:scale-95 transition-transform
              hover:border-neutral-200 border border-transparent
            `}
            >
              <div className="flex flex-col items-center justify-center h-full gap-2 md:gap-8">
                <DynamicFeedIcon className="text-[4rem] md:text-[6rem]" />
                <Typography
                  variant={matches ? 'h5' : 'h6'}
                  textAlign="center"
                  letterSpacing="1px"
                  fontFamily="gothambold"
                >
                  BULK
                  <br />
                  UPLOADS
                </Typography>
              </div>
            </a>
          )}

          {user?.user_groupName !== 'ImageChecker' && selectedSite?.SiteCode !== 'OC' && selectedSite?.SiteCode !== 'JQ' && (
            <a
              href="/data-management/brand-grouping"
              className={`
              bg-white
              shadow
              w-[150px] h-[150px] md:w-64 md:h-64
              active:scale-95 transition-transform
              hover:border-neutral-200 border border-transparent
            `}
            >
              <div className="flex flex-col items-center justify-center h-full gap-2 md:gap-8">
                <GroupAddIcon className="text-[4rem] md:text-[6rem]" />
                <Typography
                  variant={matches ? 'h5' : 'h6'}
                  textAlign="center"
                  letterSpacing="1px"
                  fontFamily="gothambold"
                >
                  BRAND
                  <br />
                  GROUPING
                </Typography>
              </div>
            </a>
          )}
          {user?.user_groupName !== 'ImageChecker' && selectedSite?.SiteCode === 'JQ' && (
            <a
              href="/margin-threshold"
              className={`
              bg-white
              shadow
              w-[150px] h-[150px] md:w-64 md:h-64
              active:scale-95 transition-transform
              hover:border-neutral-200 border border-transparent
            `}
            >
              <div className="flex flex-col items-center justify-center h-full gap-2 md:gap-8">
                <InsightsIcon className="text-[4rem] md:text-[6rem]" />
                <Typography
                  variant={matches ? 'h5' : 'h6'}
                  textAlign="center"
                  letterSpacing="1px"
                  fontFamily="gothambold"
                >
                  MARGIN
                  <br />
                  THRESHOLD
                </Typography>
              </div>
            </a>
          )}
          {(user?.user_groupName === 'ImageChecker' || (isUserAdminOrAppManager && selectedSite?.SiteCode !== 'OC' && selectedSite?.SiteCode !== 'JQ')) && (
            <a
              href="/image-checker"
              className={`
              bg-white
              shadow
              w-[150px] h-[150px] md:w-64 md:h-64
              active:scale-95 transition-transform
              hover:border-neutral-200 border border-transparent
            `}
            >
              <div className="flex flex-col items-center justify-center h-full gap-2 md:gap-8">
                <ImageSearchIcon className="text-[4rem] md:text-[6rem]" />
                <Typography
                  variant={matches ? 'h5' : 'h6'}
                  textAlign="center"
                  letterSpacing="1px"
                  fontFamily="gothambold"
                >
                  Image
                  <br />
                  Checker
                </Typography>
              </div>
            </a>
          )}
        </Stack>
        <p className="absolute text-lg bottom-4 right-4 text-error-400">
          Work In Progress - Not Final Version
        </p>
      </div>
    </Container>
  );
}

export default Dashboard;
