import postRequest from '../../../postRequest';

/**
 * Adds user into PRISM db.
 *
 * @param {string} accountName - andrew.fletcher
 * @param {number} userType - 1
 * @param {number} userRight - 10
 * @param {Array[number]} siteIds - [0, 1]
 * @param {number} status - 0
 * @param {number} apiAccessType - 1
 * @param {string} userName - andrew.fletcher@jdplc.com
 * @param {string} cloneUserEmail - clonefrom.fletcher@jdplc.com

 * @returns {object} { Success: true, Error: null }
 */
function postCloneUser(accountName, userType, userPermission, siteIds, apiAccessType, userName, groupId, groupName, cloneUserEmail) {
  const endpoint = `/users-management/clone-user?cloneUser=${cloneUserEmail}`;
  const body = {
    accountName,
    userType,
    userPermission,
    siteIds,
    apiAccessType,
    userName,
    groupId,
    groupName,
  };
  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Clone User')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postCloneUser;
