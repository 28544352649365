import postRequest from '../../../postRequest';

/**
 * Disable user into PRISM db.
 *
 * @param {string} groupName - xyz
 */
function postDisableGroup(groupName) {
  const endpoint = '/users-management/disable-user-group';

  const body = new FormData();
  body.append('groupName', groupName.trim());

  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Disable Group', null, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postDisableGroup;
