import AddIcon from '@mui/icons-material/Add';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import {
  DataGrid, GridOverlay,
  GridToolbar,
} from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import getAllUserGroup from '../../api/private/users_management/gets/getAllUserGroup';
import getAllUsers from '../../api/private/users_management/gets/getAllUsers';
import postDisableGroup from '../../api/private/users_management/posts/postDisableGroup';
import postEnableGroup from '../../api/private/users_management/posts/postEnableGroup';
import EditGroupModal from './modals/EditGroup';
import NewGroupModal from './modals/NewGroup';

function CustomizedNoRowsOverlay(onAddNewGroup) {
  const user = useSelector((state) => state.user.userProfile);

  return (
    <GridOverlay className="h-1/3">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex" p={2}>
        <h1 className="text-2xl text-stone-500 font-gothambook">No results have been returned</h1>
        {user.user_permission === 'Admin' && (
          <>
            <h1 className="mt-1 mb-5 text-sm font-bold text-stone-700">Change search parameter or</h1>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              sx={{
                borderRadius: '20px',
                width: 150,
                height: 40,
                backgroundColor: '#384451',
              }}
              onClick={onAddNewGroup}
            >
              Add Group
            </Button>
          </>
        )}
      </Box>
    </GridOverlay>
  );
}
const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

function UserGroupList({
  search,
  groupCount,
  onAddNewGroup,
}) {
  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(20);
  const [loadingTable, setLoadingTable] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [selectedGroup, setSelectedGroup] = React.useState({});
  const [selectedGroupId, setSelectedGroupId] = React.useState('');
  const [buttonAnchor, setButtonAnchor] = React.useState(null);
  const [disableEnableGroup, setDisableEnableGroup] = React.useState(false);
  const [updatedGroup, setUpdatedGroup] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const getAllUserList = async () => {
    try {
      const userList = await getAllUsers('');
      return userList;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    return true;
  };

  const getAllUserGroups = async (searchKey) => {
    setLoadingTable(true);
    try {
      const userGroupList = await getAllUserGroup(searchKey);
      return userGroupList;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoadingTable(false);
    return true;
  };

  const disableOrEnableGroup = async () => {
    if (selectedGroup.Status === 1) {
      await postDisableGroup(selectedGroup.GroupName)
        .then(() => {
          toast.success(`Group ${selectedGroup.GroupName} disabled`);
          setDisableEnableGroup(() => !disableEnableGroup);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      await postEnableGroup(selectedGroup.GroupName)
        .then(() => {
          toast.success(`Group ${selectedGroup.GroupName} enabled`);
          setDisableEnableGroup(() => !disableEnableGroup);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    setOpenDialog(false);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleMenuOpen = (event, group) => {
    event.stopPropagation();
    setSelectedGroup(group.row);
    setSelectedGroupId(group.id);
    setButtonAnchor(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    event.stopPropagation();
    setSelectedGroupId(null);
    setButtonAnchor(null);
  };

  const columns = [
    {
      field: 'GroupName',
      headerName: 'Group Name',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'GroupAdmin',
      headerName: 'Group Admin',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'UserCount',
      headerName: 'User count',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'InsertedDate',
      headerName: 'Created Date',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })
          .replace(',', '');
      },
    },
    {
      field: 'UpdatedDate',
      headerName: 'Updated Date',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        const date = params.value === '0001-01-01T00:00:00' ? null : new Date(params.value);
        return date === null ? 'No Update' : date.toLocaleString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })
          .replace(',', '');
      },
    },

    {
      field: 'Status',
      headerName: 'Status',
      flex: 1,
      minWidth: 150,
      renderCell: (group) => (
        <span>
          {group.row.Status === 1 ? <Chip label="Active" color="success" variant="outlined" />
            : <Chip size="small" label="Not Active" color="error" variant="outlined" />}
        </span>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      renderCell: (group) => (
        <>
          <Button
            size="small"
            onClick={(event) => {
              // setSelectedGroup(group);
              handleMenuOpen(event, group);
            }}
          >
            <MoreVertSharpIcon />
          </Button>
          <Menu
            id={`menu-${group.id}`}
            anchorEl={buttonAnchor}
            open={selectedGroupId === group.id}
            onClose={(event) => handleMenuClose(event)}
          >
            <MenuItem
              onClick={(event) => {
                setSelectedGroup(group.row);
                setOpenModal(true);
                handleMenuClose(event);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem onClick={(event) => {
              setSelectedGroupId(group.Id);
              setSelectedGroup(group.row);
              handleClickOpenDialog();
              handleMenuClose(event);
            }}
            >
              {group.row.Status === 1 ? 'Disable' : 'Enable'}
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  const handleModalClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const getTableDetails = async () => {
      setLoadingTable(true);
      try {
        const userGroupList = await getAllUserGroups(search);
        const userList = await getAllUserList();
        setRows(userGroupList.data);
        setUsers(userList.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoadingTable(false);
      }
    };
    getTableDetails();
  }, [search, groupCount, updatedGroup, disableEnableGroup]);

  const handleProcessRowUpdateError = React.useCallback((error) => {
    toast.error(error);
  }, []);

  return (
    <div className="width: '100%'; margin-right: -20px;">
      <Box
        component="div"
        sx={{
          height: 'calc(100vh - 220px)',
          width: 'calc(100vw - 126px)',
          mt: -1,
          ml: 3.5,
          mr: -30,
        }}
      >
        {open && (
          <NewGroupModal
            open={open}
            handleClose={() => {
              setOpen(false);
            }}
          />
        )}
        <DataGrid
          rows={rows}
          columns={columns}
          experimentalFeatures={{ newEditingApi: true }}
          getRowId={(r) => r.Id}
          slots={{
            toolbar: GridToolbar,
            noRowsOverlay: (() => CustomizedNoRowsOverlay(onAddNewGroup)),
          }}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          disableSelectionOnClick
          className="bg-white"
          getRowHeight={() => 'auto'}
          sx={{
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          pagination
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[20, 30, 40]}
          loading={loadingTable}
        />
      </Box>
      {openModal && (
        <EditGroupModal
          title={`${selectedGroup?.GroupName} `}
          open={openModal}
          onClose={handleModalClose}
          buttonLabel="Confirm"
          buttonOnClick={() => {
            setOpenModal(false);
          }}
          selectedGroup={selectedGroup}
          handleClose={() => {
            setOpenModal(false);
          }}
          updatedGroup={updatedGroup}
          setUpdatedGroup={setUpdatedGroup}
          userList={users}
        />
      )}
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle>
          <Typography color="textPrimary" align="center" variant="h4">
            Group:
            {' '}
            {selectedGroup.GroupName}
          </Typography>
        </DialogTitle>
        <Divider>
          {selectedGroup.Status === 1
            ? <Chip color="primary" label="Note: Group can not be disabled if user exist in this Group." size="small" />
            : <Chip color="primary" label="Please confirm" size="small" />}

        </Divider>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography align="center" color="textSecondary" variant="h5">
              Are you sure you wants to
              {' '}
              {selectedGroup.Status === 1 ? 'Disable' : 'Enable'}
              {' '}
              this group?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ cursor: 'pointer' }} variant="outlined" color="info" onClick={handleCloseDialog}>Cancel</Button>
          <Button
            sx={{ cursor: 'pointer' }}
            variant="outlined"
            color="success"
            onClick={disableOrEnableGroup}
          >
            {selectedGroup.Status === 1 ? 'Disable' : 'Enable'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UserGroupList;
