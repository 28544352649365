import postRequest from '../../../postRequest';

/**
 * Update user into PRISM db.
 *
 * @param {string} accountName - andrew.fletcher
 * @param {number} userType - 1
 * @param {number} userRight - 10
 * @param {Array[number]} siteIds - [0, 1]
 * @param {number} status - 0
 * @param {number} apiAccessType - 1
 * @param {string} id - 3fa85f64-5717-4562-b3fc-2c963f66afa6com
 * @returns {object} { Success: true, Error: null }
 */
function postUpdateUser(
  accountName,
  userType,
  userPermission,
  siteIds,
  apiAccessType,
  groupId,
  groupName,
  id,
  status,
) {
  const endpoint = '/users-management/update-user';

  const body = {
    accountName,
    userType,
    userPermission,
    siteIds,
    apiAccessType,
    groupId,
    groupName,
    id,
    status,
  };
  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Update User')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postUpdateUser;
