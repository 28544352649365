// import { CheckBox } from '@mui/icons-material';
import CommentIcon from '@mui/icons-material/Comment';
import {
  Box, Checkbox, Divider,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import postUpdateGroup from '../../../api/private/users_management/posts/postUpdateGroup';
import Modal from '../../../components/Modal';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#dadde9',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

function EditGroupModal({
  open,
  handleClose,
  selectedGroup,
  updatedGroup,
  setUpdatedGroup,
  userList,
}) {
  const [groupName, setGroupName] = React.useState(selectedGroup?.GroupName);
  const sites = useSelector((state) => state.fascias);
  const [siteIds, setSitesIds] = React.useState([]);
  const users = userList.filter((user) => user.GroupId === selectedGroup.Id);
  const flatFasciaList = sites.flatMap((obj) => obj.Sites);

  const defaultFaciaOptions = flatFasciaList
    .filter((obj) => selectedGroup.SiteIds.includes(obj.SiteId))
    .map((obj) => ({ label: obj.Name, value: obj.SiteId }));

  const [selectedOptions, setSelectedOptions] = React.useState(defaultFaciaOptions);

  const allSites = sites.flatMap((obj) => obj.Sites)
    .map((obj) => ({ label: obj.Name, value: obj.SiteId }));

  const allSelected = selectedOptions?.length === allSites?.SiteIds?.length;
  const customOptions = [{ label: 'Select All', value: 'all' }, ...allSites];

  const updateGroup = async (id, name, sitesInGroup) => {
    await postUpdateGroup(id, name, sitesInGroup.length === 0 ? selectedGroup.SiteIds : sitesInGroup)
      .then(() => {
        handleClose(false);
        toast.success('Successfully updated group');
        setUpdatedGroup(() => !updatedGroup);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const isIndeterminate = selectedOptions.length > 0 && !allSelected;
  const handleFaciaChange = (event, values) => {
    const isSelectAllSelected = values.some((option) => option.label === 'Select All');
    const allOptionsSelected = customOptions.filter((option) => option.label !== 'Select All');

    if (isSelectAllSelected) {
      if (selectedOptions.length === allOptionsSelected.length) {
        setSelectedOptions([]);
      } else {
        setSelectedOptions(allOptionsSelected);
        setSitesIds(allOptionsSelected.map((option) => option.value));
      }
    } else {
      const uniqueValues = values.filter(
        (option, index, self) => option.label !== 'Select All'
          && index === self.findIndex((o) => o.value === option.value),
      );
      setSelectedOptions(uniqueValues);
      setSitesIds(uniqueValues.map((option) => option.value));
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${selectedGroup.GroupName}`}
      titleVariant="h4"
      buttonLabel="SAVE"
      buttonOnClick={() => updateGroup(selectedGroup.Id, groupName, siteIds)}
    >
      <Box display="flex" gap={1}>
        <Stack spacing={1} width={400}>
          <Typography variant="h5">Group Details</Typography>
          <TextField
            required
            label="GroupName"
            fullWidth
            onChange={(event) => setGroupName(event.target.value)}
            defaultValue={selectedGroup.GroupName}
          />
          <Stack direction="row" spacing={1}>
            <Box display="flex" alignItems="flex-start">
              <Stack spacing={1}>
                <Typography variant="h5">Access</Typography>
                <Autocomplete
                  size="small"
                  fullWidth
                  sx={{
                    minWidth: 200, maxHeight: 400, overflow: 'auto',
                  }}
                  multiple
                  value={selectedOptions}
                  options={customOptions}
                  onChange={handleFaciaChange}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        checked={
                          option.label === 'Select All'
                            ? allSelected
                            : selected
                        }
                        indeterminate={option.label === 'Select All' && isIndeterminate}
                      />
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField required {...params} variant="outlined" label="Facias" />
                  )}
                />
              </Stack>
            </Box>
          </Stack>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack>
          <Typography variant="h5">Users</Typography>
          <Box>
            <Stack>
              <List sx={{
                maxHeight: 500, overflow: 'auto',
              }}
              >
                {users.map((user) => (
                  <ListItem
                    key={user.Id}
                    disableGutters
                    secondaryAction={(
                      <IconButton aria-label="Fascias">
                        <HtmlTooltip
                          sx={{ maxHeight: 200, overflow: 'auto' }}
                          title={(
                            <>
                              <Typography color="inherit">
                                {user.AccountName}
                                {' '}
                                Access:
                              </Typography>
                              <ul type="1">
                                {
                                  flatFasciaList
                                    .filter((site) => user.SiteIds.includes(site.SiteId))
                                    .map((obj) => (
                                      <li>{obj.Name}</li>
                                    ))
                                }
                              </ul>
                            </>
                          )}
                        >
                          <CommentIcon />
                        </HtmlTooltip>
                      </IconButton>
                    )}
                    sx={{
                      borderBottom: '1px solid',
                    }}
                  >
                    <ListItemText
                      primary={`${user.UserName}`}
                      sx={{
                        ml: 1,
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
}

export default EditGroupModal;
