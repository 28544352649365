// import { CheckBox } from '@mui/icons-material';
import {
  Box, Checkbox, Divider, FormHelperText,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import postCloneUser from '../../../api/private/users_management/posts/postCloneUser';
import Modal from '../../../components/Modal';

function CloneUserModal({
  open,
  handleClose,
  userTypesMapping,
  apiAccessTypesMapping,
  userPermissionMapping,
  cloneUserMapping,
  usersCount,
  setUsersCount,
}) {
  // eslint-disable-next-line no-control-regex
  const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
  const [email, setEmail] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [userType, setUserType] = React.useState(userTypesMapping.JdplcUser);
  const [accessType, setAccessType] = React.useState([]);
  const [selectedUserType, setSelectedUserType] = React.useState([]);
  const [userPermission, setUserPermission] = React.useState(null);
  const user = useSelector((state) => state.user.userProfile);
  const sites = useSelector((state) => state.fascias);
  const [siteIds, setSitesIds] = React.useState([]);
  const [allFacias, setAllFacias] = React.useState([]);
  const [groupFacias, setGroupFacias] = React.useState([]);
  const [cloneUserEmail, setCloneUserEmail] = React.useState([]);

  const groups = useSelector((state) => state.groups.groupList);
  const [selectedGroup, setSelectedGroup] = React.useState([]);

  const [errorExists, setErrorExists] = React.useState(false);
  const filteredPermissions = Object.entries(userPermissionMapping)
    .filter(([key]) => (user.user_permission === 'GroupAdmin' ? key !== 'Admin' : true))
    .map(([label, value]) => ({ label, value }));
  const mapEnumToOption = (enumOptions) => Object.entries(enumOptions).map(([label, value]) => ({
    label,
    value,
  }));
  const userTypesArray = mapEnumToOption(userTypesMapping);
  const accessTypesArray = mapEnumToOption(apiAccessTypesMapping);

  React.useEffect(() => {
    const sitesFlattened = sites.flatMap((obj) => obj.Sites);
    setAllFacias(sitesFlattened);
    if (open) {
      const state = {
        gName: groups?.find((obj) => obj.Id === cloneUserMapping.GroupId),
        sites: sitesFlattened.filter((siteids) => cloneUserMapping.SiteIds.includes(siteids.SiteId)),
        userPermissions: filteredPermissions.find((per) => per.label === cloneUserMapping.UserPermission),
        userType: userTypesArray.find((per) => per.label === cloneUserMapping.UserType),
        accessType: accessTypesArray.find((per) => per.label === cloneUserMapping.ApiAccessType),
        cloneUserEmail: cloneUserMapping.UserName,
      };
      setUserPermission(state.userPermissions);
      setSelectedGroup(state.gName);
      setAccessType(state.accessType);
      setSelectedUserType(state.userType);
      setSitesIds(state.sites);
      setCloneUserEmail(state.cloneUserEmail);
      const groupSites = sitesFlattened
        .filter((obj) => state.gName?.SiteIds?.includes(obj?.SiteId));

      setGroupFacias([{
        SiteId: 0, Name: 'Select All', SiteCode: '', IsLead: false, LanguageId: 0,
      }, ...groupSites]);
    }
  }, [open]);

  const disableCreateButton = () => {
    const emptyUsername = username?.length === 0;
    const emptyUserType = selectedUserType == null;
    const emptyApiAccessType = accessType == null;
    const emptyEmail = email?.length === 0;
    const emptyChosenGroup = selectedGroup === undefined;
    const emptyFacias = siteIds.length === 0;
    return emptyUsername
      || emptyUserType
      || emptyApiAccessType
      || emptyEmail
      || errorExists
      || emptyChosenGroup
      || emptyFacias;
  };
  const closeModel = () => {
    handleClose(false);
  };

  const addUser = async () => {
    await postCloneUser(
      username,
      selectedUserType.value,
      userPermission.value,
      siteIds.map((s) => s.SiteId),
      accessType.value,
      email,
      selectedGroup.Id,
      selectedGroup.GroupName,
      cloneUserEmail,
    )
      .then(() => {
        handleClose(false);
        setUsersCount(usersCount + 1);
        toast.success('Successfully cloned user');
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const allSelected = siteIds?.length === groupFacias.length;

  const isIndeterminate = siteIds.length > 0 && !allSelected;

  const handleFaciaChange = (event, values) => {
    const selectedAllFacias = groupFacias.filter((option) => option.Name !== 'Select All');

    if (values.some((option) => option.Name === 'Select All')) {
      if (selectedAllFacias.length === siteIds.length) {
        setSitesIds([]);
      } else {
        setSitesIds(selectedAllFacias.map((site) => site));
      }
    } else {
      setSitesIds(values.filter((option) => option.Name !== 'Select All').map((option) => option));
    }
  };

  const handleGroupChange = (value) => {
    setSitesIds([]);
    if (value !== null) {
      const groupSites = allFacias
        .filter((obj) => value.SiteIds?.includes(obj?.SiteId));
      setGroupFacias([{
        SiteId: 0, Name: 'Select All', SiteCode: '', IsLead: false, LanguageId: 0,
      }, ...groupSites]);
      setSelectedGroup(value);
    }
  };

  React.useEffect(() => {
    if ((!email.includes('@jdplc.com') && !email.includes('@cosmossport.gr')) && userType === 1) {
      setErrorExists(true);
    } else {
      setErrorExists(false);
    }
  }, [email]);

  const selectedAll = groupFacias.filter((option) => option.Name !== 'Select All').length === siteIds.length;

  return (
    <Modal
      open={open}
      onClose={closeModel}
      title="Clone User"
      titleVariant="h4"
      buttonLabel="SAVE"
      buttonOnClick={() => addUser()}
      disableButton={disableCreateButton()}
    >
      <Box display="flex" gap={2}>
        <Stack spacing={3} sx={{ width: 600 }}>
          <Typography variant="h5">User Details</Typography>
          <TextField
            required
            label="Email"
            fullWidth
            type="email"
            error={email.length > 0 && (!email.match(emailRegex) || errorExists)}
            onChange={(event) => setEmail(event.target.value)}
            helperText={email.length > 0 && errorExists ? 'Invalid email address' : ''}
          />
          <TextField
            required
            label="Full Name"
            fullWidth
            InputLabelProps={{ shrink: username?.length > 0 }}
            onChange={(event) => setUsername(event.target.value)}
          />
          <Stack direction="row" spacing={2}>
            <Autocomplete
              fullWidth
              display="flex"
              id="tags-standard"
              value={selectedGroup}
              options={
                user.user_permission === 'Admin'
                  ? groups
                  : groups.filter((obj) => obj.Id === user.user_groupId)

              }
              getOptionLabel={(option) => (`${option.GroupName}`) || ''}
              onChange={(event, value) => {
                handleGroupChange(value);
              }}
              renderInput={(params) => <TextField required {...params} label="Group" />}
              isOptionEqualToValue={(option, value) => option === value}
            />
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                display="flex"
                id="tags-standard"
                value={userPermission}
                options={filteredPermissions}
                getOptionLabel={(option) => (`${option.label}`) || ''}
                onChange={(event, value) => {
                  setUserPermission(value);
                }}
                renderInput={(params) => <TextField required {...params} label="User-permission" />}
                isOptionEqualToValue={(option, value) => option === value}
              />
            </FormControl>
          </Stack>
          <Typography variant="h5">Settings</Typography>
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth error={!email.includes('@jdplc.com') && userType === 1} disabled={user.user_permission !== 'Admin'}>
              <Autocomplete
                fullWidth
                display="flex"
                id="tags-standard"
                value={selectedUserType}
                options={userTypesArray}
                getOptionLabel={(option) => (`${option.label}`) || ''}
                onChange={(event, value) => {
                  setUserType(value);
                  setSelectedUserType(value);
                }}
                renderInput={(params) => <TextField required {...params} label="User Type" />}
                isOptionEqualToValue={(option, value) => option === value}
              />
              {(!email.includes('@jdplc.com') && !email.includes('@cosmossport.gr')) && userType === 1 && (
                <FormHelperText>
                  JdplcUser account can only be applied for @jdplc.com or @cosmossport.gr emails.
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth disabled={user.user_permission !== 'Admin'}>
              <Autocomplete
                fullWidth
                display="flex"
                id="tags-standard"
                value={accessType}
                options={accessTypesArray}
                getOptionLabel={(option) => (`${option.label}`) || ''}
                onChange={(event, value) => {
                  setAccessType(value);
                }}
                renderInput={(params) => <TextField required {...params} label="Access Type" />}
                isOptionEqualToValue={(option, value) => option === value}
              />
            </FormControl>
          </Stack>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Box display="flex" alignItems="flex-start">
          <Stack spacing={3}>
            <Typography variant="h5">Access</Typography>
            <Autocomplete
              fullWidth
              sx={{
                minWidth: 200, maxWidth: 400, maxHeight: 300, overflow: 'auto',
              }}
              multiple
              options={groupFacias}
              value={siteIds}
              onChange={handleFaciaChange}
              disableCloseOnSelect
              getOptionLabel={(option) => option?.Name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    checked={
                      option.Name === 'Select All'
                        ? selectedAll
                        : selected
                    }
                    indeterminate={option.Name === 'Select All' && isIndeterminate}

                  />
                  {option.Name}
                </li>
              )}
              renderInput={(params) => (
                <TextField required {...params} variant="outlined" label="Facias" />
              )}
            />
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}

export default CloneUserModal;
