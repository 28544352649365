import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

// import isProduction from '../helper/IsProduction';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../helper/LocalStorage';
import { addPending, removePending } from './pendingRequests';

function setTokens(accessToken, refreshToken, tokenExpires) {
  const tokenExpiresNumber = new Date(new Date(tokenExpires).getTime());
  Cookies.set('AccessToken', accessToken, { expires: tokenExpiresNumber });
  setLocalStorage('RefreshToken', refreshToken);
  setLocalStorage('AccessTokenExpires', tokenExpires);
}

/**
 * Handle instantiating axios.
 *
 * @return {AxiosStatic} Instance of axios
 */
function instanceAxios() {
  // const REQUEST_TIMEOUT_DURATION_MS = 200000;

  // const LIVE_URL = process.env.REACT_APP_LIVE_URL;
  // const BASE_URL = process.env.REACT_APP_BASE_URL;
  const LIVE_URL = 'https://api.prism.jdplc.com';
  const DEV_URL = 'https://api-dev.prism.jdplc.com'; // 'http://localhost:8100';
  const STG_URL = 'https://api-staging.prism.jdplc.com';

  // eslint-disable-next-line no-console
  // console.log(process.env);

  const ENV = process.env.REACT_APP_ENV; // 'development', 'staging', 'production'
  let url;
  if (ENV === 'production') {
    url = LIVE_URL;
  } else if (ENV === 'staging') {
    url = STG_URL;
  } else {
    url = DEV_URL;
  }

  // if (process.env.REACT_APP_ENV === 'production') {
  // if (isProduction()) {
  // eslint-disable-next-line no-console
  // console.log('Production mode');
  //   url = LIVE_URL;
  // }

  const instance = axios.create({
    withCredentials: false,
    baseURL: url,
    headers: {
      'Accept-Language': 'en-GB',
      'Content-Type': 'application/json',
    },
    timeout: 200000,
  });

  // Setup interceptors (to automatically handle inserting up-to-date access tokens)
  instance.interceptors.request.use(
    async (config) => {
      const objConfig = config;

      // Check previous requests to cancel before the request starts
      removePending(objConfig);
      // Add current request to pending
      addPending(objConfig);

      const token = Cookies.get('AccessToken');
      if (token && objConfig.url !== '/users/refresh-token' && objConfig.url !== '/users/authenticate') {
        objConfig.headers.Authorization = `Bearer ${token}`;
      }

      const expires = getLocalStorage('AccessTokenExpires');

      if (expires != null
        && expires !== 0
        && new Date(expires) < new Date()
        && objConfig.url !== '/users/refresh-token'
        && objConfig.url !== '/users/authenticate') {
        // eslint-disable-next-line no-console
        console.log('Refreshing token');
        const body = new FormData();
        body.append('refreshToken', getLocalStorage('RefreshToken'));

        await instance.post('/users/refresh-token', body, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((res) => {
            const { data } = res;
            setTokens(data.JwtToken, data.RefreshToken, new Date(data.ValidToUtc));

            // eslint-disable-next-line no-param-reassign
            objConfig.headers.Authorization = data.JwtToken;
          })
          .catch((err) => {
            console.error(`Refresh Token - ${err}`);
            // Usually if the request fails then it because the refresh token has expired
            // so this just logs the user out fully.
            removeLocalStorage('RefreshToken');
            removeLocalStorage('AccessTokenExpires');
            Cookies.remove('AccessToken');
            window.location.href = `${window.location.protocol}//${window.location.host}/login`;
            toast.error('Session Expired Please Re-Login');
          });
      }

      return objConfig;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    (response) => {
      removePending(response); // Remove this request at the end of the request
      const debugEnabled = getLocalStorage('debug-prism');
      // eslint-disable-next-line no-console
      if (debugEnabled === 'true') console.log('%c DEBUG ', 'background: #EDF7EE; color: #2E6930', response);
      return response;
    },
    async (error) => {
      if (error.response?.data.message === 'Re-login required.') {
        removeLocalStorage('RefreshToken');
        removeLocalStorage('AccessTokenExpires');
        Cookies.remove('AccessToken');
        window.location.href = `${window.location.protocol}//${window.location.host}/login`;
        console.error('Error:', error.response?.data.message);
      }
      if (axios.isCancel(error)) {
        console.error(`Repeated Request: ${error.message}`);
      } else {
        console.error(`UnknownError: ${error.message}`);
      }
      const debugEnabled = getLocalStorage('debug-prism');
      if (debugEnabled === 'true') console.error('%c DEBUG ', 'background: #FAE9E7; color: #7A160B', error);
      removePending(error);
      return Promise.reject(error);
    },
  );

  return instance;
}

export default instanceAxios;
