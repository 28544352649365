import postRequest from '../../../postRequest';

/**
 * Disable user into PRISM db.
 *
 * @param {string} id - 3fa85f64-5717-4562-b3fc-2c963f66afa6com
 * @returns {object} { Success: true, Error: null }
 */
function postDisableUser(id) {
  const endpoint = '/users-management/disable-user';

  const body = { id };
  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Disable User')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postDisableUser;
