import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React from 'react';

function ConfirmationModal({
  open, onClose, onConfirm, message, user,
}) {
  const styles = {

    modal: {

      width: '300px',

      margin: 'auto',

      padding: '20px',

      border: '1px solid #ccc',

      borderRadius: '10px',

      textAlign: 'center',

    },

    line: {

      borderTop: '1px solid #ccc',

      margin: '20px 0',

    },

    buttonContainer: {

      display: 'flex',

      justifyContent: 'space-around',

    },

    button: {

      padding: '10px 20px',

      backgroundColor: '#f0f0f0',

      border: '1px solid #ccc',

      borderRadius: '5px',

      cursor: 'pointer',

    },

    disabledButton: {

      padding: '10px 20px',

      backgroundColor: '#e0e0e0',

      border: '1px solid #ccc',

      borderRadius: '5px',

      cursor: 'not-allowed',

    },

  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        width: 600, // Increased width for less cramped text
        bgcolor: 'background.paper',
        p: 4,
        m: 'auto',
        mt: '20%',
        borderRadius: 2, // Added rounded corners
      }}
      >
        <Typography
          variant="h4"
          style={{ fontweight: 'bold' }}
          sx={{
            mt: 1, display: 'center', font: 100, justifyContent: 'flex-center',
          }}
        >
          Disable User
        </Typography>
        {(
          user.row.Status === 'Disabled' ? (
            <Typography
              variant="h6"
              sx={{
                mt: 4, display: 'center', justifyContent: 'flex-center',
              }}
            >
              {' '}
              User is aready disabled
            </Typography>
          ) : (
            <Typography
              variant="h6"
              sx={{
                mt: 4, display: 'center', justifyContent: 'flex-center',
              }}
            >
              {message}
            </Typography>
          )
        )}
        <div style={styles.line} />
        <Box sx={{ mt: 7, display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="outlined" color="secondary" onClick={onClose} sx={{ mr: 1 }}>CANCEL</Button>
          <Button variant="contained" color="primary" disabled={user.row.Status === 'Disabled'} onClick={onConfirm}>DISABLE</Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ConfirmationModal;
