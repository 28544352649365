import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {
  Timeline, TimelineConnector, TimelineContent, TimelineItem, TimelineOppositeContent, TimelineSeparator,
} from '@mui/lab';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import { Pagination, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import getRequest from '../../api/getRequest';
import parseDateString from '../../utils/parseDateString';

function UserActivityLogs({ search }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [logs, setLogs] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [totalPages, setTotalPages] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [expandedLogs, setExpandedLogs] = useState([]);

  const { id } = useParams();

  const getUserActivityLogs = () => {
    const paginationUrl = `&page=${currentPage}&size=${itemsPerPage}`;
    const endpoint = `/users-management/user-management-logs?search=${search === undefined ? '' : search}&${paginationUrl}`;

    return new Promise((resolve, reject) => {
      getRequest(endpoint, 'Get User Activity Logs')
        .then((response) => resolve(response))
        .catch((error) => {
          reject(error instanceof Error ? error : new Error(error));
        });
    });
  };

  const fetchLogs = async () => {
    const logsData = await getUserActivityLogs();
    setLogs(logsData.data.Data);
    setTotalPages(logsData.data.Pagination.PageCount);
    setTotalRows(logsData.data.Summary.TotalRowCount);
  };

  useEffect(() => {
    fetchLogs();
  }, [search, currentPage, itemsPerPage, id]);

  const handleExpandClick = (logId) => {
    setExpandedLogs((prevExpandedLogs) => {
      if (prevExpandedLogs.includes(logId)) {
        return prevExpandedLogs.filter((log) => log !== logId);
      }
      return [...prevExpandedLogs, logId];
    });
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeItemsPerPage = (event) => {
    const newItemsPerPage = event.target.value;
    const newPageCount = Math.ceil(totalRows / newItemsPerPage);
    const newPage = Math.min(currentPage, newPageCount);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newPage);
  };

  return (
    <section className="flex flex-col gap-1">
      <div className="py-2 bg-white border rounded-lg">
        {logs.length > 0 ? (
          <Timeline sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 'none',
              width: '110px',
              padding: 0,
            },
          }}
          >
            {logs.map((log) => {
              const jsonStartIndex = log.LogMessage.indexOf('[');
              const jsonEndIndex = log.LogMessage.lastIndexOf(']') + 1;
              const nonJsonMessage = log.LogMessage.substring(0, jsonStartIndex);
              const jsonString = log.LogMessage.substring(jsonStartIndex, jsonEndIndex);

              if (jsonStartIndex !== -1 && jsonEndIndex !== -1) {
                try {
                  const parsedJson = JSON.parse(jsonString);
                  const isExpanded = expandedLogs.includes(log.InsertDate);
                  return (
                    <TimelineItem key={log.LogId}>
                      <TimelineOppositeContent style={{ padding: '2px 10px 6px 10px' }}>
                        <Stack>
                          <Typography variant="body2" style={{ color: '#6f7686' }}>
                            {parseDateString(log.InsertDate)}
                          </Typography>
                        </Stack>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <CheckCircleIcon sx={{ color: '#1aa0b3' }} />
                        <TimelineConnector sx={{ backgroundColor: '#1aa0b3' }} />
                      </TimelineSeparator>
                      <TimelineContent style={{ padding: '0 10px 6px 10px' }}>
                        <Typography>{nonJsonMessage}</Typography>
                        {isExpanded ? (
                          <>
                            <Typography className="cursor-pointer text-primary-600" onClick={() => handleExpandClick(log.InsertDate)}>
                              Show less
                            </Typography>
                            <pre className="whitespace-pre-wrap">{JSON.stringify(parsedJson, null, 2)}</pre>
                          </>
                        ) : (
                          <Typography className="cursor-pointer text-primary-600" onClick={() => handleExpandClick(log.InsertDate)}>
                            Show more
                          </Typography>
                        )}
                        <Typography className="flex items-center" variant="body2" style={{ color: '#6f7686' }}>
                          <PersonOutlineIcon
                            style={{
                              color: '#6f7686',
                              marginRight: '5px',
                            }}
                            fontSize="small"
                          />
                          {' '}
                          {log.Changer}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  );
                } catch (error) {
                  console.error('Error parsing JSON:', error);
                  return null;
                }
              } else {
                return (
                  <TimelineItem key={log.LogId} className="{margin:0,padding:0}">
                    <TimelineOppositeContent style={{ padding: '0px 0px 0px 0px' }}>
                      <Stack>
                        <Typography variant="body2" style={{ color: '#6f7686' }}>
                          {parseDateString(log.InsertDate)}
                        </Typography>
                      </Stack>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <CheckCircleIcon sx={{ color: '#1aa0b3' }} />
                      <TimelineConnector sx={{ backgroundColor: '#1aa0b3' }} />
                    </TimelineSeparator>
                    <TimelineContent style={{ padding: '0 0px 0px 0px' }}>
                      <Typography>{log.LogMessage}</Typography>
                      <Typography className="flex items-center" variant="body2" style={{ color: '#6f7686' }}>
                        <PersonOutlineIcon
                          style={{
                            color: '#6f7686',
                            marginRight: '5px',
                          }}
                          fontSize="small"
                        />
                        {' '}
                        {log.Changer}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              }
            })}
          </Timeline>
        ) : (
          <Typography>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex">
              <h1 className="text-2xl text-stone-500 font-gothambook">No results have been returned</h1>
            </Box>
          </Typography>
        )}
      </div>
      <div className="flex flex-col items-center gap-6 p-2 pl-4 ml-auto bg-white border rounded-lg sm:flex-row w-max">
        <FormControl sx={{ minWidth: 120 }} variant="standard" size="small" className="flex flex-row items-center">
          <Typography variant="body2" className="mr-4">Rows per page:</Typography>
          <Select
            labelId="logs-per-page"
            value={itemsPerPage}
            onChange={handleChangeItemsPerPage}
            autoWidth
            size="small"
            variant="standard"
          >
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
        />
      </div>
    </section>
  );
}

UserActivityLogs.propTypes = {
  search: PropTypes.string,
};

UserActivityLogs.defaultProps = {
  search: PropTypes.string,
};

export default UserActivityLogs;
